export default {
  computed: {
    cancellationInfo() {
      const details = this.cancellationDetails
      if (Object.keys(details).length != 0) {
        if (details && this.isAdmin) {
          var text = 'Cancelled'
          if (details.by_who) {
            text = text + ' by: ' + details.by_who
          }
          var parts = [ details.reason ]
          if (details.charged) {
            parts.push('fee charged')
          } else {
            parts.push('fee waived')
          }
          if (this.isAdmin) {
            if (details.cleaner_paid) {
              parts.push('cleaner paid')
            }
          }
          return text + ' (' + parts.join(', ') + ')'
        }
      } else {
        console.error('cancellationDetails is required for cancellationInfo')
      }
    },
    cancellationInfoForBreakdown() {
      const details = this.cancellationDetails
      if (Object.keys(details).length != 0) {
        if (details) {
          var text = 'Cancellation Fee'
          var parts = []
          if (details.charged) {
            parts.push('charged')
          } else {
            parts.push('waived')
          }
          if (this.isAdmin) {
            if (details.cleaner_paid) {
              parts.push('cleaner paid')
            }
          }
          return text + ' (' + parts.join(', ') + ')'
        }
      } else {
        console.error('cancellationDetails is required for cancellationInfoForBreakdown')
      }
    }
  }
}
